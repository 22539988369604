@import "../../../sass/utilities/variables";

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #333;
  color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  font-size: 1.25rem;
  z-index: 100;

  &__title {
    font-size: 1.25rem;
    font-weight: 400;

    a {
      color: #fff;
      text-decoration: none;
    }
  }

  &__menu-icon {
    cursor: pointer;
  }

  &__links {
    a {
      color: #fff;
      text-decoration: none;
      font-size: 1rem;
      padding: 0.5em 0.75em;
      border-radius: 0.25rem;

      @media (min-width: 768px) {
        margin-right: 1em;
      }

      &.active {
        background-color: rgba(255, 255, 255, 0.2);
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.075);
      }
    }

    &.visible {
      display: flex;
    }

    &.hidden {
      display: none;
    }

    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
      margin-top: 1.25rem;

      a {
        width: 100%;
        text-align: center;

        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  &.mobile {
    flex-direction: column;

    .navbar__container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &__links {
      flex-direction: column;
      margin-top: 10px;
      background-color: red;

      &.open {
        display: flex;
      }

      a {
        margin: 5px 0;
      }
    }
  }
}

.navbar__spacer {
  height: 3em;
}
