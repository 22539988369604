@import "../../../sass/utilities/variables";

.footer {
  padding: 1.5rem 0;
  background-color: #333;
  color: #fff;
  width: 100%;

  &__text {
    font-size: 0.95rem;
    text-align: center;
    margin: 0;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0;
  }

  &__name {
    text-decoration: none;
    transition: all 0.125s ease-in-out;
  }

  &__language {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;

    &__select--icon {
      height: 2.25rem;
    }

    // TODO: hide the default select and create a custom one
    &__select {
      margin: 0 0.5rem;
      padding: 0.25rem 0.5rem;
      border: 1px solid #fff;
      border-radius: 0.25rem;
      background-color: transparent;
      color: #fff;
      font-size: 0.85rem;
      cursor: pointer;
      transition: all 0.125s ease-in-out;

      &:hover {
        background-color: #fff;
        color: #333;
        cursor: pointer;
      }

      &__option {
        background-color: #fff;
        color: #333;
        font-size: 0.85rem;
        cursor: pointer;
        transition: all 0.125s ease-in-out;

        &:hover {
          background-color: #333;
          color: #fff;
          cursor: pointer;
        }
      }
    }

    &__icon {
      height: 1.5rem;
    }
  }
}
