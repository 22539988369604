@import "../../../sass/utilities/variables";

.experience {
  display: flex;

  .timeline-divider {
    width: 1px;
    background-color: #ccc;
    position: relative;

    @media (min-width: 768px) {
      margin: 0 1rem;
    }

    @media (max-width: 768px) {
      margin: 0 0.5rem;
    }

    @media (max-width: 576px) {
      margin: 0 0.25rem;
    }

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #6a6a6a;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:not(:last-child) {
    .experience__description:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #ccc;
      margin: 2.5rem 0;
    }
  }

  &__description {
    flex: 1;
    padding-left: 1rem;
    // Max width 90% of parent
    max-width: 95%;

    &__title {
      margin-bottom: 0.3125rem;
    }

    &__company {
      font-size: 1.15rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    &__date {
      color: #6a6a6a;
      font-size: 0.95rem;
      margin-bottom: 0.5rem;
    }

    &__details {
      margin-bottom: 1rem;
    }

    &__tasks--title {
      color: #6a6a6a;
      font-style: italic;
      font-weight: bold;
      margin-bottom: 0.25rem;
    }

    &__tasks {
      font-size: 0.95rem;
      margin-left: 1.25rem;
      list-style: none;

      & li::before {
        content: '•';
        color: #6a6a6a;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }

      &__task {
        margin-bottom: 0.25rem;
      }
    }
  }
}
