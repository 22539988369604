@import "../../../sass/utilities/variables";

.card {
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  padding: 1rem;
  margin-bottom: 1rem;

  &__title {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  &__content {
    margin-bottom: 0.5rem;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }
}

