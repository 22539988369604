// Variables
// Colors
$white: #fff;
$black: #000;
$lightGray: #f4f4f4;
$darkestGray: #171717;
$darkGray: #808080;
$gray: #adadad;
$green: #11917c;
$darkBlue: #043b6a;
$blue: #0a4391;
$lightBlue: #b3d9ff;
$beige: #f7f7e8;
$darkBrown: #4d3319;
$brown: #cc9865;
$red: #ff6666;

$titleAccent: #202020;

$buttonAccent: #5f5f5f;
$buttonSuccess: #319644;
$buttonSuccessAccent: #0e7621;
$buttonDanger: #d63a32;
$buttonDangerAccent: #b92b3d;

// Sizes
$max-width: 90%;
