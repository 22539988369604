@import "../../../sass/utilities/variables";

.projects {
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: $max-width;
    padding: 0 1rem;
    width: 100%;

    @media (min-width: 992px) {
      flex-wrap: wrap;

      .card {
        flex: 0 0 48%;
        margin-bottom: 1rem;
      }
    }

    @media (max-width: 992px) {
      .card {
        flex: 0 0 100%;
      }
    }

    .card {
      &__content {
        h3 {
          color: #6a6a6a;
          margin-bottom: 0.5rem;
        }

        p {
          font-size: 0.95rem;
          margin-bottom: 0.75rem;
        }

        .card__link,
        .card__link:visited {
          color: #275efe;
        }

        br {
          display: block;
          content: "";
            margin-top: 0.25rem;
        }
      }
    }
  }
}
